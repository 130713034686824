<template>
  <div>
    <div class="row mb-2 align-items-center">
      <div class="col-12">
        <h4 class="mb-3 text-center">Overview of PSC inspection by country by year</h4>
      </div>
      <div class="col-1 text-left">
        <label for="month" class="col-form-label">YEAR</label>
      </div>
      <div class="col-2">
        <select name="month" id="month" class="form-control form-control-sm" v-model="params.year">
          <option :value="null">--- SELECT YEAR ---</option>
          <option :value="year" v-for="year in years">{{year}}</option>
        </select>
      </div>
      <div class="col-9 text-right justify-content-end">
        <button id="committeeReport" aria-expanded="false" aria-haspopup="true" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                data-toggle="dropdown">
          <font-awesome-icon class="mr-1" icon="download"/>
          Download Report
        </button>
        <div aria-labelledby="committeeReport" class="dropdown-menu">
          <button class="dropdown-item text-secondary font-weight-bolder" @click="downloadReport('excel')"  type="button">
            <font-awesome-icon icon="file-excel"/>
            Excel
          </button>
          <button class="dropdown-item text-secondary font-weight-bolder" @click="downloadReport('pdf')"  type="button">
            <font-awesome-icon icon="file-pdf"/>
            PDF
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table text-center table-sm small e-tbl">
          <thead class="bg-white">
          <tr>
            <th class="align-middle" scope="col">Year</th>
            <th class="align-middle" scope="col">Country</th>
            <th class="align-middle" scope="col">Nbr Inspection</th>
            <th class="align-middle" scope="col">With Deficiency Inspection</th>
            <th class="align-middle" scope="col">No Deficiency Inspection</th>
            <th class="align-middle" scope="col">NDR (%)</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="loading">
            <tr>
              <td colspan="100%">
                <app-loading></app-loading>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="report in newInspectionByCountry ">
              <td>{{params.year}}</td>
              <td>{{report.country}}</td>
              <td>{{ report.inspection_count }}</td>
              <td>{{report.with_deficiency_count}}</td>
              <td>{{report.no_deficiency_count}}</td>
              <td>{{report.no_deficiency_rate}}</td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import _ from "lodash"
import {PSC_REPORT_INDEX} from '@/services/ConstantService';
import {UrlService} from '@/services/UrlService';
import axios from 'axios';
import {GlobalService} from '@/services/GlobalService';

export default {
  name: "PscInspectionByCountry",
  components: {AppLoading},
  data(){
    return {
      loading:false,
      params:{
        year:new Date().getFullYear()
      },
      years:Array.from({length:100},(_,i)=>new Date().getFullYear() - i)
    }
  },
  computed:{
    ...mapGetters(['inspectionByCountry']),
    newInspectionByCountry() {
      if (this.params.year === null) {
        return [];
      }
      return this.inspectionByCountry;
    },
    noOfDeficiency() {
      return _.sum(this.newInspectionByCountry.map(item => item.no_deficiency_count));
    }
  },
  methods:{
    ...mapActions(['getInspectionReportByCountry']),
    async getData(){
      this.loading=true
      await this.getInspectionReportByCountry(this.params)
      this.loading=false
    },
    async downloadReport(fileType){
      const params=Object.assign({},this.params)
      params[fileType]=1;
      const uri = [PSC_REPORT_INDEX, 'deficiency-rate-country'].join('/')
      const queryString= UrlService.setQueryString(params)
      swal.fire({
        'title': `GENERATING REPORT <br>Please wait.....`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
      swal.showLoading()
      await axios({
        url:uri + queryString,
        method: 'GET',
        responseType: 'blob'
      })
      .then(response => {
        swal.close()
        let mime_type = fileType==='pdf' ? 'application/pdf' : 'application/vnd.ms-excel'
        let url = window.URL.createObjectURL(new Blob([response.data],{type:mime_type}))

        fileType = (fileType !== 'pdf') ? 'xlsx' : 'pdf';

        let filename=`PSC_INSPECTION_BY_COUNTRY_${this.params.year}.${fileType}`
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        GlobalService.handleError(error, 'DOWNLOAD PSC DEFICIENCY REPORT PER COUNTRY')
        return false
      })
    }
  },
  created(){
   this.getInspectionByCountry=_.debounce(this.getData,250)
    this.getData()
  },

  watch:{
    "params.year"(){
      if (this.params.year !== null) {
        this.getInspectionByCountry()
      }
    }
  }

}
</script>


<style scoped>

</style>
